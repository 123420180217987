import request from "@/utils/request";
export default class ApiSubmitPage {
  // 联系申请
  static contactApply(data) {
    return request({
      url: "api/v1/contact/apply",
      method: "POST",
      urlType: "website",
      data,
    });
  }
}

